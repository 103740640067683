//
// Form Wizard
//

// twitter-bs-wizard

.twitter-bs-wizard {
    .twitter-bs-wizard-nav {
        padding: 4px;
        background-color: rgba($primary, 0.1);

        .step-number {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 34px;
            border: 2px solid $primary;
            color: $primary;
            text-align: center;
            border-radius: 50%;

            @media (max-width: 991.98px) {
                display: block;
                margin: 0 auto 8px !important;
            }
        }

        .nav-link {
            @media (min-width: 992px) {
                text-align: left;
            }
            &.active {
                background-color: transparent;
                color: $gray-700;

                .step-number {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    .twitter-bs-wizard-pager-link {
        padding-top: 24px;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li {
            display: inline-block;
            a {
                display: inline-block;
                padding: 0.47rem 0.75rem;
                background-color: $primary;
                color: $white;
                border-radius: 0.25rem;
            }
            &.disabled {
                a {
                    cursor: not-allowed;
                    background-color: lighten($primary, 8%);
                }
            }

            &.next {
                float: right;
            }
        }
    }
}

.twitter-bs-wizard-tab-content {
    padding-top: 24px;
    min-height: 262px;
}

.form-wizard-wrapper {
    label {
        font-size: 14px;
        text-align: right;
    }
}

.wizard {
    // step
    .steps {
        > ul {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            @media (max-width: 1199.98px) {
                flex-direction: column;
            }

            > a,
            > li {
                flex-basis: 0;
                flex-grow: 1;
            }

            > li {
                width: 100%;

                a {
                    display: block;
                    padding: 0.5rem 1rem;
                    color: $gray-700;
                    /* font-weight: $fw-medium; */

                    background-color: rgba($primary, 0.1);
                }
            }

            .current-info {
                position: absolute;
                left: -999em;
            }
        }

        .number {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 34px;
            border: 2px solid $primary;
            color: $primary;
            text-align: center;
            border-radius: 50%;
            margin-right: 0.5rem;
        }

        .current {
            a,
            a:active,
            a:hover {
                background-color: rgba($primary, 0.2);
                color: $gray-700;
                .number {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    // content
    > .content {
        background-color: transparent;
        padding: 14px;
        margin-top: 0;
        border-radius: 0;
        min-height: 150px;
        > .title {
            position: absolute;
            left: -999em;
        }
        > .body {
            width: 100%;
            height: 100%;
            padding: 14px 0 0;
            position: static;
        }
    }

    // actions

    > .actions {
        position: relative;
        display: block;
        text-align: right;
        width: 100%;
        > ul {
            display: block;
            text-align: right;
            padding-left: 0;
            > li {
                display: inline-block;
                margin: 0 0.5em;
            }
        }
        a,
        a:active,
        a:hover {
            background-color: $primary;
            border-radius: 4px;
            padding: 8px 15px;
            color: $white;
        }

        .disabled {
            a,
            a:active,
            a:hover {
                opacity: 0.65;
                background-color: $primary;
                color: $white;
                cursor: not-allowed;
            }
        }
    }

    // verticl wixard
    &.vertical-wizard {
        display: flex;
        flex-wrap: wrap;

        // step
        .steps {
            > ul {
                flex-direction: column;

                > li {
                    width: 100% !important;
                }
            }
        }

        .steps,
        .content,
        .actions {
            width: 100%;
        }

        .steps {
            @media (min-width: 1200px) {
                width: 25%;
            }
        }

        .content {
            padding: 24px;
            @media (min-width: 1200px) {
                width: 75%;
                padding: 12px 24px;
            }

            > .body {
                padding: 0;
            }
        }
    }
}
