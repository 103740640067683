body::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

body::-webkit-scrollbar:hover {
    width: 10px;
    height: 8px;
}

body {
    mask-size: 100% 200px;
    mask-position: left bottom;
}

body:hover {
    mask-position: left bottom;
    -webkit-mask-position: left top;
}

body::-webkit-scrollbar-track {
    margin-top: 73px;
    margin-left: 10px;
    background: rgba(231, 209, 209, 0.3);
    border-radius: 20px;
    box-shadow: inset 0 0 6px rgba(65, 64, 64, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(63, 63, 65, 0.3); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid rgba(101, 103, 104, 0.3); /* creates padding around scroll thumb */
}

.container-fluid {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}
