#link:hover {
    background: rgba(48, 55, 87, 0.2);
    color: white;
    border-left: solid 2px white;
    box-shadow: 5px 5px 5px 1px rgba(87, 87, 136, 0.75);
}

.title {
    position: relative;
    display: block;
    padding: 0, 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
};

#not-link:hover {
    background: rgba(48, 55, 87, 0.2);
    color: white;
}

/* .toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: #48526e;
    cursor: pointer;
    border-radius: 10px;
}

.toggle:hover {
    background: red;
}

.toggle::before {
    content: '\f0c9';
    font-family: fontAwesome;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: #fff;
}

.toggle:hover::before {
    content: '\f00d';
    font-family: fontAwesome;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: #fff;
}
 */
.main {
    margin-left: 10%;
}

.sub-menu {
    width: 5px;
}

.connection-sub-menu {
    width: 5px;
    padding-left: 20px !important;
}

.adminChevronTransform {
    min-width: 0rem !important;
    padding-bottom: 0rem !important;
    line-height: 1 !important;
    margin-top: 3px !important;
}

.adminSubmenu {
    width: 150px !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
    left: 150px! important;
}