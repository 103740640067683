.badge {
    &[href] {
        &:hover,
        &:focus {
            color: $white;
        }
    }
}

// Regular badge
@mixin badge-variant($bg) {
    color: $white;
    background-color: rgba($bg, 1) !important;

    &[href] {
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
            background-color: rgba($bg, 1);
        }
    }
}

// Soft badge
@mixin badge-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18) !important;

    &[href] {
        &:hover,
        &:focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.4);
        }
    }
}

@each $color, $value in $theme-colors {
    .badge {
        &.bg-#{$color} {
            &[href] {
                &:hover,
                &:focus {
                    background-color: darken($value, 4%) !important;
                }
            }
        }
    }

    .badge {
        &.bg-light {
            color: $body-color !important;

            &[href] {
                &:hover,
                &:focus {
                    color: $body-color !important;
                }
            }
        }
    }

    .badge-#{$color} {
        @include badge-variant($value);
    }

    .badge-soft-#{$color} {
        @include badge-variant-soft($value);
    }
}

.rounded-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
}

// Dark badge

.badge.bg-dark {
    color: $light;
}
